import React from "react";
import "./AboutCards.css";
import profile from "../assets/profile.jpg";
import mission from "../assets/mission1.jpeg";
import relation from "../assets/relation.jpeg";
import innovation from "../assets/innvotion.jpg";

const AboutCards = () => {
  return (
    <div className="about-container">
      {/* <h2 className="about-title">About Us</h2> */}
      <div className="about-cards">
        <div className="about-card">
          <img src={profile} alt="Company Profile" className="card-img" />
          <div className="about-card-content">
            <h3>Company Profile</h3>
            <p>
              Interface11 is a Global IT Consulting & Training company that has
              been providing high value-added business solutions to enterprises
              of all sizes. We have proven competencies in various business and technology areas.
            </p>
          </div>
        </div>
        <div className="about-card">
          <img src={mission} alt="Mission and Vision" className="card-img" />
          <div className="about-card-content">
            <h3>Vision & Mission</h3>
            <p><strong>Vision:</strong> To become an active growth enabler for our clients.</p>
            <p><strong>Mission:</strong> To provide committed services and assist customers in managing time and money efficiently.</p>
          </div>
        </div>
        <div className="about-card">
          <img src={relation} alt="Relationships" className="card-img" />
          <div className="about-card-content">
            <h3>Relationship Matters</h3>
            <p>
              At Interface11, we believe in making relationships, not just clients. Whether big or small, we treat all our clients with the utmost care and exceed expectations.
            </p>
          </div>
        </div>
        <div className="about-card">
          <img src={innovation} alt="Innovation" className="card-img" />
          <div className="about-card-content">
            <h3>Innovation in Creation</h3>
            <p>
              Innovation is key at Interface11. We constantly find creative solutions to help our clients stay ahead of the competition.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCards;
