import React from "react";
import img7 from "../assets/img7";
import img9 from "../assets/img9.jpeg";
import it from '../assets/it.jpg';
import consultt from '../assets/consultt.jpeg';
import "./Contentbig.css";

const Contentbig = () => {
  return (
    <div className="container">
      <div className="content2">
        <div className="back-img1">
          <img src={it} alt="IT Consulting" />
        </div>
        <div className="text2">
          <h2>IT Training</h2>
          <h4>
            Team Of Dynamic Professionals Educating The Workforce Entering The
            IT Industry
          </h4>
          <p>
            Interface11 solutions is a leader in Online IT Training solutions for
            various new generation Software Trainings like Machine-Learning, Devops, Angular, React JS, C & C++, Data Science, AWS, Java Full Stack and much
            more, precisely addressing the business needs of our customers.
          </p>
          <button className="btn-content2">Read More</button>
        </div>
      </div>
      
      <div className="content1">
        <div className="text1">
        <h2>IT Consulting</h2>
<h4>We take care of the technical stuff so you don't have to.</h4>
          <p>
            Our engineers, IT consultants and project managers combine
            technical, interpersonal and management skill with a thorough
            understanding of business processes to consistently deliver quality
            outcomes. Our ability to combine technology skills with an in-depth
            understanding of business processes is what has made us one of the
            fastest growing IT service companies in our sector.
            We take these courses Java Full Sack, Software Testing, UI/UX Design, Cloud(AWS/Azure), Oracle DBA,Data Science(AI/ML), Data Analytics.
          </p>
          <button className="btn-content">Read More</button>
        </div>
        <div className="back-img2 img02">
          <img src={consultt} alt="IT Training" />
        </div>
      </div>
    </div>
  );
};

export default Contentbig;
