import React from 'react';
import c1 from '../assets/c1.jpg';
import c2 from '../assets/c2.jpg';
import c3 from '../assets/c3.png';
import c4 from '../assets/c4.jpg';
import c5 from '../assets/c5.jpg';
import c6 from '../assets/c6.jpg';
import c7 from '../assets/c1.jpg';
import './Website.css';


const Commercial = () => {
  return (
    <div className='container'>
      <main className='content   '>
         <h1>Commercial Websites</h1>
        <div className="card-grid1">
          <div className="card1">
            <img src={c1} alt="Curcial Business" />
            <p>Curcial Business Information</p>
          </div>
          <div className="card1">
            <img src={c2} alt="Contact Information" />
            <p>Contact Information</p>
          </div>
          <div className="card1">
            <img src={c3} alt="Clear Navigation" />
            <p>Clear Navigation</p>
          </div>
          <div className="card1">
            <img src={c4} alt="Security" />
            <p>Security</p>
          </div>
          <div className="card1">
            <img src={c5} alt="Social Media Integration" />
            <p>Social Media Integration</p>
          </div>
          <div className="card1">
            <img src={c6} alt="A Mobile-Ready Version" />
            <p>A Mobile-Ready Version</p>
          </div>
          <div className="card1">
            <img src={c7} alt="FAQ" />
            <p>FAQ</p>
          </div>
        </div>    
        </main>  
    </div>
  )
}

export default Commercial
