import React from 'react';
import HeroSection from '../components/HeroSection';
import Content from '../components/Content';
import TechnologyCarousel from '../components/TechnologyCarousel';
import Contentbig from '../components/Contentbig';
import Cards from '../components/Cards';

const Home = () => {
  return (
    <div>
      <HeroSection />
      <Content />
      <TechnologyCarousel />
      <Contentbig />
      <Cards />
    </div>
  );
};

export default Home;
