import React from 'react';

import './Footer.css'; // You can customize styling in this file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-info">
          <p>
            Copyright © {new Date().getFullYear()}, All Right Reserved Interface11
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
