import React from "react";
import "./servicemain.css";
import pic1 from "./pic1.jpg";
import pic2 from "./pic2.jpg";
import pic3 from "./pic3.jpg";
import pic4 from "./pic4.jpg";
import pic5 from "./pic5.jpg";
import mobilee from '../assets/mobilee';
import content1 from "./content1.png";
import content2 from "./content2.png";
import content3 from "./content3.png";
import content4 from "./content4.png";
import contentt5 from "../assets/contentt5.png";
import content6 from "./content6.png";
import content7 from "./content7.png";
import content8 from "./content8.png";
import onlinedigital from "../assets/onlinedigital.jpg";

const Servicemain = () => {
  return (
    <div>
      <div className="heading-service">
        <h1>
          S<span className="highlight">ER</span>VICES
        </h1>
        <h4>WHERE QUALITY IS MEASURED BY YOUR SATISFACTION AND SUCCESS</h4>
      </div>
      <div id="content">
        <p id="para">
          At Interface11, IT means achieving groundbreaking results, ultimate
          satisfaction at total peace of mind which allows you to not only run
          and maintain your business but take it to the next level. Our plethora
          of solutions and services combined with our healing and indigenous
          approach ensures that your requirements are met on time, within
          budget, with unsurpassed quality, greater efficiency and
          responsiveness to your business, and the ability to transform
          investments into strategic initiatives rather than tactical functions.
        </p>
      </div>
      <div id="images">
        <div id="id1">
          <img src={pic1} alt="Software Development" className="cimages" />
          <h2 className="orange">SOFTWARE DEVELOPMENT</h2>
        </div>
        <div id="id2">
          <img src={pic2} alt="Android Development" className="cimages" />
          <h2 className="blue">ANDROID DEVELOPMENT</h2>
        </div>
        <div id="id3">
          <img src={pic3} alt="Web Designing" className="cimages" />
          <h2 className="red">WEB DESIGNING</h2>
        </div>
        <div id="id4">
          <img src={pic4} alt="Web Development" className="cimages" />
          <h2 className="teal">WEB DEVELOPMENT</h2>
        </div>
        <div id="id5">
          <img src={pic5} alt="Digital Marketing" className="cimages" />
          <h2 className="lightblue">DIGITAL MARKETING</h2>
        </div>
      </div>

      <div className="content-container-blue">
        <div className="content-text-blue">
          <h2>IT Consulting</h2>
          <hr />
          <p>
            Interface11 Solutions IT Consulting is directed towards IT
            initiatives that companies wish to take up in the quest to sustain
            and grow the business. With specialist knowledge and experience
            across many different market sectors, the Interface11 consultants
            can confidently deliver the right solution for you – now and for the
            future.
          </p>
          <button className="btn1">Read More</button>
        </div>

        <div className="img-blue">
          <img src={content1} />
        </div>
      </div>

      <div className="content-container-white">
        <div className="img-white imgWhite">
          <img src={content2} />
        </div>
        <div className="content-text-white">
          <h2>Online & Corporate Training</h2>
          <hr />
          <p>
            Interface11 Solutions is Leader in Online and Corporate IT Training
            solutions for various new generation Software Trainings like Angular, AWS, C & C++, Devops, Java Full Stack, PHP, React JS, Power BI and much more precisely
            addressing business needs of our customers. We feel pleasure in
            introducing ourselves as Top rated trainers in the field of
            Information Technology and placement Consultants. Founded by IT
            experience people, our mission is to deliver the highest-quality,
            value-added services in the Field of Training, Job support and
            consulting for both their short-term and long-term needs.
          </p>
          <button className="btn2">Read More</button>
        </div>
      </div>
      <div className="content-container-blue">

        <div className="content-text-blue">
          <h2>Software Development</h2>
          <hr />
          <p>
            Interface11 development services are meant for designing a software
            that has been designed, developed and tested for a specific business
            requirement. Interface11 has more well-organized software
            development processes that are cost effective yet provide high
            quality deliverables. It has acquired complete knowledge in the art
            of planning and designing custom software solutions, which is the
            key for successful IT development services.
          </p>
          <button className="btn1">Read More</button>
        </div>
        <div className="img-blue imgblue">
          <img src={content3} />
        </div>
      </div>
      <div className="content-container-white">
        <div className="img-white mobile">
          <img src={mobilee} />
        </div>
        <div className="content-text-white">
          <h2>Mobile App Development</h2>
          <hr />
          <p>
            The mobile app development world is changing every while. Mobile
            devices across the globe are being used for varied purposes. There
            is need to utilise latest technologies and stay adrift of this
            rapidly changing scenario. If website was a way to increase your ROI
            in the last decade, then custom mobile application is the name of
            the game in the present while.
          </p>
          <button className="btn2">Read More</button>
        </div>
      </div>
      <div className="content-container-blue">
        <div className="content-text-blue">
          <h2>Commercial Web Development</h2>
          <hr />
          <p>
            We excel in building commercial websites right from general to
            E-commerce websites including Portfolio and Designing websites at
            the best possible cost. “So that getting your business an online
            platform doesn’t bother your pocket.” Customers need to be engaged
            on a continuous and on-going basis in order to provide maximum
            satisfaction. A strong Digital Experience is the key! Companies that
            adapt to customer needs and expectations are successful in engaging
            and satisfying their customers.
          </p>
          <button className="btn1">Read More</button>
        </div>
        <div className="img-blue">
          <img src={contentt5} />
        </div>
      </div>
      <div className="content-container-white">
        <div className="img-white">
          <img src={onlinedigital} />
        </div>
        <div className="content-text-white">
          <h2>Online/Digital Marketing</h2>
          <hr />
          <p>
            We provide the services that are listed below. “So that our clients
            maintain a constant hold over their positions on Google.”
          </p>
          <div className="lists">
          <ol>
            <li>SEO</li>
            <li>SEM</li>
            <li>SMO</li>
          </ol>
          </div>
          <button className="btn2">Read More</button>
        </div>
      </div>
      <div className="content-container-blue">
       
        <div className="content-text-blue">
          <h2>Business Solutions</h2>
          <hr />
          <p>
            We ease our partners' complicated business procedures by creating
            systems/software that suits their unique requirements. We provide
            personalized solutions to all our clients and make sure to provide
            the best at the lowest cost. “Because we believe every unique
            business needs unique solutions.” Our commitment is to deliver the
            best services at the most competitive prices, without compromising
            on quality. Whether you're a startup or an established enterprise,
            we believe in creating value through innovation and collaboration.
            "Because we believe every unique business needs unique solutions."
          </p>
          <button className="btn1">Read More</button>
        </div>
        <div className="img-blue blueimg">
          <img src={content7} />
        </div>
        
        </div>
        <div className="content-container-white">
        <div className="img-white imgWhite-1">
          <img src={content8} />
        </div>
        <div className="content-text-white">
          <h2>Media Advertisement</h2>
          <hr />
          <p>
            We help you create a better perception among your clients/customers
            by creating thought-provoking media ads that help you boost your
            brand image. We create and post your media ads over appropriate
            social media sites as well as cinema halls. We also help you promote
            your brand through TV and radio advertisements.
          </p>
          <button className="btn2">Read More</button>
        </div>
      </div>
    </div>
  );
};

export default Servicemain;
