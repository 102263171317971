import React from 'react';
import clientsss from '../assets/clients2.jpg';
import './Ccards.css';
import p1 from '../assets/p1.jpg';
import niit from '../assets/niit.png';
import foodexpress from '../assets/foodexpress.jpg';
import shapeup from '../assets/shapeup.png';
import sheka from '../assets/sheka.png';
import mahindra from '../assets/mahindra.jpg';
import mindscript from '../assets/mindscript.jpg';
import croma from '../assets/croma.jpg';
import national from '../assets/national.jpg';
import dor from '../assets/dor.jpg';
import ashish from '../assets/ashish.jpg';
import oneplus from '../assets/oneplus.jpg';
import wetenjoy from '../assets/wetenjoy.jpg';
import moon from '../assets/moon.png';
import hcc from '../assets/hcc.png';
import steel from '../assets/steel.png';
import panchshil from '../assets/panchshil.jpg';
import repairlogo from '../assets/repairlogo.png';
import graphix from '../assets/graphix.jpg';
import social from '../assets/social.png';
import clientss from '../assets/clients-1.jpg';
import clientts from '../assets/client05.jpg';



const clientscard = [
    { name: 'USIT', imgSrc: p1 },
    { name: 'NIIT', imgSrc: niit },
    { name: 'FoodXpress', imgSrc: foodexpress },
    { name: 'ShapeUp', imgSrc: shapeup },
    { name: 'Sheka', imgSrc: sheka },
    { name: 'Mahindra', imgSrc: mahindra },
    { name: 'Scripts', imgSrc: mindscript },
    { name: 'Croma', imgSrc: croma},
    { name: 'National Pharma', imgSrc: national },
    { name: 'Dor', imgSrc: dor },
    { name: 'Ashish', imgSrc: ashish },
    { name: 'Oneplus', imgSrc: oneplus },
    { name: 'Wetenjoy', imgSrc: wetenjoy },
    { name: 'Moonlight', imgSrc: moon },
    { name: 'Hcc', imgSrc: hcc },
    { name: 'steel', imgSrc: steel },
    { name: 'Panchshil', imgSrc: panchshil },
    { name: 'Repairlogo', imgSrc: repairlogo },
    { name: 'Graphix', imgSrc: graphix },
    { name: 'social', imgSrc: social },
    { name: 'mindscript', imgSrc: mindscript }
   
  ];
const Ccards = () => {
  return (
    <div className='container-cards'>
        <div className='poster'>
            <img src={clientts} alt='clients'></img>
            
        </div>
        <div className="logo-grid1">
          {clientscard.map((client, index) => (
            <div className="logo-item1" key={index}>
              <img src={client.imgSrc} alt={client.name} />
            </div>
          ))}
        </div>
        
    </div>
  )
}

export default Ccards
