import React from 'react'
import About from '../components/About';
import AboutCards from '../components/AboutCards';
const AboutUs = () => {
  return (
    <div>
      <About/>
      <AboutCards/>
    </div>
  )
}

export default AboutUs
