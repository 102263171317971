import React from 'react';
import './Content.css';
import img6 from '../assets/img6'; 
import business from '../assets/business.jpeg';

const Content = () => {
  return (
    <div className='cont'>
     
      <div className='back-img'>
        <img src={business} alt="Description of the image" />
      </div>
      <div className='content-write'>
        <h3>Better IT = Better Business.</h3>
        <p>Effective IT Solutions has been providing since 2016 and currently supports a number of clients across India. Our customers operate in many industry sectors including law, retail, accounting, sales, banking and corporate. Everything we do at INTERFACE11 driven by our commitment to service.We are a friendly IT company providing all sizes of organization with leading-edge technology solutions. We were formed with the aim of assisting organizations in using modern technology with a close relationship and continual personal interaction between our staff and the client

        </p>
        <button className='btn-contentt'>Read More</button>
       
      </div>

    </div>
  );
}

export default Content;
